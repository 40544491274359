import { authenticationService, } from '@mfe-shared-lib/public-api';
/**
 * Creates HTTP interceptors for adding bearer tokens to API requests.
 */
export function createHttpInterceptors() {
    const noAuthDomains = ['login.microsoftonline.com'];
    function shouldSendAuthToken(url) {
        try {
            const parsedUrl = new URL(url, window.location.origin);
            return !noAuthDomains.some(domain => parsedUrl.hostname.endsWith(domain));
        }
        catch (error) {
            console.error('Error parsing URL:', error);
            return true;
        }
    }
    const originalFetch = window.fetch;
    const originalXHR = window.XMLHttpRequest;
    // Override Fetch API to inject auth tokens
    window.fetch = async function (resourceUrl, config = {}) {
        if (authenticationService && shouldSendAuthToken(resourceUrl.toString())) {
            const token = await authenticationService.getToken();
            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            }
        }
        return originalFetch(resourceUrl, config);
    };
    // Override XMLHttpRequest to inject auth tokens
    const originalOpen = originalXHR.prototype.open;
    originalXHR.prototype.open = function (...args) {
        this._url = args[1]; // Capture the URL being requested
        // @ts-ignore
        originalOpen.apply(this, args);
    };
    const originalSend = originalXHR.prototype.send;
    originalXHR.prototype.send = async function (body) {
        if (authenticationService && shouldSendAuthToken(this._url)) {
            const token = await authenticationService.getToken();
            if (token) {
                this.setRequestHeader('Authorization', `Bearer ${token}`);
            }
        }
        originalSend.apply(this, arguments);
    };
}
