const orgName = '@aclara';
/**
 * Generates and appends the import map to the DOM, handling production and development environments.
 *
 * @param {ClientMfeConfig} config - The client-specific configuration containing MFE information.
 */
export function generateImportMapAndAddToDom(config) {
    const importMap = constructImportMapWithApps(config);
    if (process.env.overridesEnabled) {
        handleOverrides(importMap);
    }
    else {
        appendImportMapToDom(importMap);
    }
    // Inform SystemJS to re-evaluate the import map
    window.System.prepareImport(true);
}
/**
 * Constructs an import map object with paths to all MFE applications based on the provided configuration.
 *
 * @param {ClientMfeConfig} config - The client configuration containing MFE details.
 * @returns {ImportMap} - The constructed import map object.
 */
function constructImportMapWithApps(config) {
    const protocol = location.protocol;
    const microFrontendHost = location.host;
    const importMap = { imports: {} };
    // Add root config null import map.
    importMap.imports['@aclara/root-config'] = '/nullImport.js';
    // Add client-specific applications to the import map
    if (config?.Apps) {
        config.Apps.forEach((app) => {
            const appImportKey = `${orgName}/${app.Name}`;
            const appImportValue = `${protocol}//${app.HostName || microFrontendHost}${app.EntryUri}`;
            importMap.imports[appImportKey] = appImportValue;
            app.AppImportMapEntry = {
                key: appImportKey,
                value: appImportValue,
            };
            // Add configuration file if defined
            if (app.ConfigUri) {
                const configImportKey = `${orgName}/${app.Name}-config`;
                const configImportValue = `${protocol}//${app.HostName || microFrontendHost}${app.ConfigUri}`;
                importMap.imports[configImportKey] = configImportValue;
                app.ConfigImportMapEntry = {
                    key: configImportKey,
                    value: configImportValue,
                };
            }
        });
    }
    return importMap;
}
/**
 * Handles import map overrides in development mode by adding missing imports to the override map.
 *
 * @param {ImportMap} importMap - The constructed import map object.
 */
function handleOverrides(importMap) {
    const currentOverrides = window.importMapOverrides.getOverrideMap();
    // Add any missing imports to the override map
    for (const [key, value] of Object.entries(importMap.imports)) {
        if (!(key in currentOverrides.imports)) {
            window.importMapOverrides.addOverride(key, value);
        }
    }
}
/**
 * Appends the import map as a `<script>` element to the DOM.
 *
 * @param {ImportMap} importMap - The constructed import map object.
 */
function appendImportMapToDom(importMap) {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'systemjs-importmap';
    scriptTag.textContent = JSON.stringify(importMap);
    document.head.appendChild(scriptTag);
}
