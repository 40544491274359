class ResourceLocatorService {
    locator = (module, resource, override) => {
        console.log('this is the default locator function, should be changed by the app-ao-portal mfe. locating: ' + module);
    };
    setLocator = (fn) => {
        this.locator = fn;
    };
    useLocator = (module, resource, override) => {
        return override ? this.locator(module, resource, override) : this.locator(module, resource);
    };
}
// Export both the type and the singleton instance of the service.
const resourceLocatorService = new ResourceLocatorService();
export { resourceLocatorService, ResourceLocatorService };
