/**
 * Utility functions for managing shared services in a single-spa application.
 * Responsible for initializing core services, creating HTTP interceptors, and providing a shared service registry.
 */
import { authenticationService, clientSettingsService, navigationUtilityService, resourceLocatorService, securityResourceProviderService } from '@mfe-shared-lib/public-api';
import { createHttpInterceptors } from './http-interceptors.utils';
/**
 * Initializes core shared services required by the application.
 *
 * @param {any} services - An object containing shared service instances to initialize.
 * @returns {Promise<void>} - A promise that resolves when all core services have been initialized.
 *
 * ### Function Details:
 * - Initializes the authentication service to manage user sessions.
 * - Sets up HTTP interceptors for handling secure requests.
 * - Initializes client settings and the security resource provider concurrently.
 */
export async function initializeCoreServices(services) {
    // Initialize authentication service
    await services.authenticationService.initialize();
    // Create HTTP interceptors for secure API requests
    createHttpInterceptors();
    // Initialize client settings and security resource provider in parallel
    await Promise.all([
        services.clientSettingsService.initialize(process.env.apiUrl),
        services.securityResourceProviderService.initialize(process.env.apiUrl),
    ]);
}
/**
 * Provides an object containing shared singleton service instances.
 *
 * @returns {Object} - An object containing the shared services to be used across MFEs.
 *
 * ### Function Details:
 * - Exposes key shared services such as:
 *   - `authenticationService` for managing authentication.
 *   - `clientSettingsService` for loading client-specific configurations.
 *   - `navigationUtilityService` for handling navigation utilities.
 *   - `resourceLocatorService` for locating resources dynamically.
 *   - `securityResourceProviderService` for security and access management.
 */
export function getSharedServices() {
    return {
        authenticationService: authenticationService,
        clientSettingsService: clientSettingsService,
        navigationUtilityService: navigationUtilityService,
        resourceLocatorService: resourceLocatorService,
        securityResourceProviderService: securityResourceProviderService
    };
}
/**
 * Initializes client settings service and retrieves client-specific module configuration.
 *
 * @param {any} clientSettingsService - The service used to manage client-specific settings.
 * @returns {Promise<ClientMfeConfig>} - A promise resolving to the client MFE configuration.
 *
 * ### Function Details:
 * - Initializes the client settings service.
 * - Retrieves and returns the client-specific module configuration.
 */
export async function loadClientSettings(clientSettingsService) {
    // Initialize client settings
    await clientSettingsService.initialize();
    // Retrieve client-specific module configuration
    return clientSettingsService.clientModuleConfiguration;
}
