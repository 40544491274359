import { AO_ORG_NAME } from "../constants";
export function createMfeAppDomStructure(mfeAppName, config) {
    const mfeApp = config.Apps.find(x => x.Name === mfeAppName);
    let container = document.getElementById(`single-spa-application:${AO_ORG_NAME}/${mfeAppName}`);
    if (!container) {
        container = document.createElement('div');
        container.id = `single-spa-application:${AO_ORG_NAME}/${mfeAppName}`;
        document.getElementById('mfe-container-root')?.appendChild(container);
    }
    if (!container.shadowRoot) {
        const shadowRoot = container.attachShadow({ mode: 'open' });
        // Resource isolation container
        const resourcesDiv = document.createElement('div');
        resourcesDiv.id = `${mfeAppName}-resources`;
        // Root container for the app
        const rootDiv = document.createElement('div');
        rootDiv.id = `${mfeAppName}-root`;
        // Add root element (non-Angular apps will replace this element)
        const angularRootElement = document.createElement(`${mfeAppName}-root`);
        rootDiv.appendChild(angularRootElement);
        // Load external resources from `ResourceUris`
        if (mfeApp && mfeApp.ResourceUris) {
            mfeApp.ResourceUris.forEach(uri => {
                const isCss = uri.endsWith('.css');
                const resourceElement = isCss ? document.createElement('link') : document.createElement('script');
                if (isCss) {
                    resourceElement.setAttribute('rel', 'stylesheet');
                    resourceElement.setAttribute('href', uri);
                }
                else {
                    resourceElement.setAttribute('src', uri);
                    resourceElement.setAttribute('defer', 'true');
                }
                resourcesDiv.appendChild(resourceElement);
            });
        }
        shadowRoot.appendChild(resourcesDiv);
        shadowRoot.appendChild(rootDiv);
    }
}
