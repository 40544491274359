export class ClientMfeConfig {
    /**
     * The marketing version number of the product.
     */
    MarketingVersionNumber;
    /**
     * An object containing the default state configuration for the client.
     */
    DefaultState;
    /**
     * The collection of MFE apps configured for the client.
     */
    Apps = [];
    /**
     * An optional collection of custom menu items.
     */
    CustomMenuItems = [];
    /**
     * A flag that gets set to true once all configured application modules have been initialized.
     */
    AppModulesAreInitialized = false;
    /**
     * A function that MFE apps call into to register their app module and menu item structure.
     */
    loadMfeAppModules(mfeAppName, mfeAppModules) {
        const mfeApp = this.Apps.find((app) => app.Name === mfeAppName);
        if (mfeApp === undefined) {
            console.error(`MFE app ${mfeAppName} was not found.`);
            return;
        }
        mfeApp.AppModules = mfeAppModules;
        const eventArgs = { detail: { mfeAppName: mfeAppName } };
        window.dispatchEvent(new CustomEvent('MfeAppModuleInitialized', eventArgs));
    }
}
