// TODO:  Undo hard-coding ...
export const msalConfig = {
    auth: {
        clientId: 'f6cbae5b-52ab-4ebc-adfe-84090b323e8e',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};
