import { ClientMfeConfig } from '../public-api';
class ClientSettingsService {
    // temporarily using localhost to be able to debug in VS
    FETCH_DEVICE_TYPES_API_ENDPOINT = 'https://dev.aclara.one/AO.WebAPI/api/DeviceType/GetDeviceTypesByClientId';
    FETCH_DISPLAY_SETTINGS_API_ENDPOINT = 'https://dev.aclara.one/AO.WebAPI/api/Tenant/GetClient';
    FETCH_MODULE_CONFIGURATION_API_ENDPOINT = 'getApps';
    deviceTypes;
    settings;
    moduleConfiguration;
    get clientDeviceTypes() {
        return this.deviceTypes;
    }
    get clientSettings() {
        return this.settings;
    }
    get clientModuleConfiguration() {
        return this.moduleConfiguration;
    }
    async initialize() {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.fetchClientDeviceTypes(),
                this.fetchClientDisplaySettings(),
                this.fetchClientModuleConfiguration()
            ])
                .then(() => resolve())
                .catch(error => {
                console.log(`An error occurred while attempting to retrieve client settings:  ${error}`);
                reject();
            });
        });
    }
    async fetchClientDeviceTypes() {
        return fetch(this.FETCH_DEVICE_TYPES_API_ENDPOINT)
            .then((response) => response.json())
            .then((data) => this.deviceTypes = data.ReturnData);
    }
    async fetchClientDisplaySettings() {
        return fetch(this.FETCH_DISPLAY_SETTINGS_API_ENDPOINT)
            .then((response) => response.json())
            .then((data) => this.settings = data.ReturnData);
    }
    async fetchClientModuleConfiguration() {
        return fetch(this.FETCH_MODULE_CONFIGURATION_API_ENDPOINT)
            .then((response) => response.json())
            .then((data) => {
            this.moduleConfiguration = new ClientMfeConfig();
            this.moduleConfiguration.MarketingVersionNumber = data.MarketingVersionNumber;
            this.moduleConfiguration.DefaultState = {
                AppName: data.DefaultState.AppName,
                TopLevelState: data.DefaultState.TopLevelState,
                RouteIdentifier: data.DefaultState.RouteIdentifier
            };
            this.moduleConfiguration.Apps = data.Apps;
            return this.moduleConfiguration;
        });
    }
}
// Export both the type and the singleton instance of the service.
const clientSettingsService = new ClientSettingsService();
export { ClientSettingsService, clientSettingsService };
