import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../types/msal-config';
class AuthenticationService {
    msalInstance;
    constructor() {
        this.msalInstance = new PublicClientApplication(msalConfig);
    }
    async checkAuthenticated() {
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            return Promise.resolve();
        }
        else {
            await this.redirectToLogin();
            return Promise.reject('User is not authenticated');
        }
    }
    getCurrentUser() {
        return this.msalInstance.getActiveAccount();
    }
    async getToken() {
        const account = this.msalInstance.getActiveAccount();
        let failureReason = '';
        if (!account) {
            failureReason = 'No active account was found.';
            console.error(`${failureReason}  Redirecting to login page ...`);
            await this.redirectToLogin();
            return Promise.reject(failureReason);
        }
        const request = {
            account: account,
            scopes: [`${msalConfig.auth.clientId}/.default`]
        };
        try {
            const response = await this.msalInstance.acquireTokenSilent(request);
            return Promise.resolve(response.accessToken);
        }
        catch (error) {
            let failureReason = `Failed to acquire token silently. Error: ${error}`;
            console.log(`${failureReason}  Redirecting to login page ...`);
            await this.redirectToLogin();
            return Promise.reject(failureReason);
        }
    }
    async initialize() {
        await this.msalInstance.initialize();
        await this.msalInstance.handleRedirectPromise().then(this.handleResponse.bind(this));
    }
    async logout() {
        try {
            await this.msalInstance.logoutRedirect();
        }
        catch (error) {
            console.error('Error during logout:', error);
        }
    }
    async redirectToLogin() {
        return this.msalInstance.loginRedirect();
    }
    handleResponse(response) {
        if (response !== null) {
            this.msalInstance.setActiveAccount(response.account);
        }
        else {
            this.redirectToLogin();
        }
    }
}
// Export both the type and the singleton instance of the service.
const authenticationService = new AuthenticationService();
export { AuthenticationService, authenticationService };
