class SecurityResourceProviderService {
    FETCH_SECURITY_RESOURCES_ENDPOINT = 'https://dev.aclara.one/AO.Portal.WebAPI/api/Security/GetAccessibleResources';
    securityResources;
    get userSecurityResources() {
        return this.securityResources;
    }
    async initialize() {
        return this.fetchSecurityResources();
    }
    async fetchSecurityResources() {
        return fetch(this.FETCH_SECURITY_RESOURCES_ENDPOINT)
            .then((response) => response.json())
            .then((data) => this.securityResources = data.ReturnData);
    }
}
// Export both the type and the singleton instance of the service.
const securityResourceProviderService = new SecurityResourceProviderService();
export { SecurityResourceProviderService, securityResourceProviderService };
