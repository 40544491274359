class SecurityResourceProviderService {
    FETCH_SECURITY_RESOURCES_ENDPOINT = 'AO.Portal.WebAPI/api/Security/GetAccessibleResources';
    securityResources;
    get userSecurityResources() {
        return this.securityResources;
    }
    async initialize(apiUrl) {
        return this.fetchSecurityResources(apiUrl);
    }
    async fetchSecurityResources(apiUrl) {
        return fetch(apiUrl + this.FETCH_SECURITY_RESOURCES_ENDPOINT)
            .then((response) => response.json())
            .then((data) => this.securityResources = data.ReturnData);
    }
}
// Export both the type and the singleton instance of the service.
const securityResourceProviderService = new SecurityResourceProviderService();
export { SecurityResourceProviderService, securityResourceProviderService };
