import { navigateToUrl, registerApplication, start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import { authenticationService, clientSettingsService, navigationUtilityService, resourceLocatorService, securityResourceProviderService } from '@mfe-shared-lib/public-api';
import microfrontendLayout from "./microfrontend-layout.html";
import { createHttpInterceptors } from './http-interceptors';
import '../assets/styles/_ao-base.scss';
const AO_DEFAULT_APP_NAME = '@aclara/app-ao-portal';
const AO_DEFAULT_ROUTE = 'ao.mdm.dashboard';
const addDummyIfNotExist = ['@aclara/app-phase-detect-menu', '@aclara/app-notifications'];
const orgName = "@aclara";
let appInitializationTracker = {};
let config;
/**
 * Main entry point for the startup logic of the application.
 * Responsible for initializing services and loading micro front-end (MFE) apps.
 */
async function initializeApp() {
    const services = getSharedServices();
    // Initialize necessary services like authentication and client settings
    await initializeCoreServices(services);
    await loadClientSettings();
    // Attach lifecycle event listeners
    attachStartupEventListeners();
    // Load and register MFE applications
    loadApps(config, services);
    await loadAppModuleConfig(config);
    navigateToUrl('#/ao'); // Navigate to default route
}
/**
 * Retrieves shared singleton services to be used across MFEs.
 * @returns {Object} - Shared services object
 */
function getSharedServices() {
    return {
        'authenticationService': authenticationService,
        'clientSettingsService': clientSettingsService,
        'navigationUtilityService': navigationUtilityService,
        'resourceLocatorService': resourceLocatorService,
        'securityResourceProviderService': securityResourceProviderService
    };
}
/**
 * Initializes core services like authentication and client settings.
 */
async function initializeCoreServices(services) {
    await authenticationService.initialize();
    createHttpInterceptors(); // Create HTTP interceptors for secure requests
    // Initialize both client settings and security resource provider concurrently
    await Promise.all([
        clientSettingsService.initialize(),
        securityResourceProviderService.initialize()
    ]);
}
/**
 * Loads the client settings and initializes the app initialization tracker.
 */
async function loadClientSettings() {
    config = clientSettingsService?.clientModuleConfiguration;
    appInitializationTracker = config.Apps.reduce((tracker, app) => ({
        ...tracker,
        [app.Name]: false
    }), {});
}
/**
 * Attaches event listeners for MFE initialization and loading.
 */
function attachStartupEventListeners() {
    window.addEventListener('MfeAppModuleInitialized', broadcastAllAppsInitialized);
    window.addEventListener('MfeAppModuleLoaded', routeToDefault);
}
/**
 * Broadcasts an event when all MFE applications are initialized.
 */
function broadcastAllAppsInitialized(event) {
    const e = event;
    const mfeAppName = e?.detail?.mfeAppName;
    if (mfeAppName && appInitializationTracker[mfeAppName] !== undefined) {
        appInitializationTracker[mfeAppName] = true;
        if (Object.values(appInitializationTracker).every(status => status === true)) {
            config.AppModulesAreInitialized = true;
            window.dispatchEvent(new CustomEvent('AllMfeAppModulesHaveInitialized'));
            window.removeEventListener('MfeAppModuleInitialized', broadcastAllAppsInitialized);
        }
    }
}
/**
 * Generates and appends an import map with the MFE apps to the DOM.
 */
function constructImportMapWithApps(config) {
    const protocol = location.protocol;
    const microFrontendHost = location.host;
    const mfeFrameworkApps = [
        {
            name: 'root-config',
            entryUri: '/nullImport.js'
        },
        {
            name: 'app-navbar',
            entryUri: '/mfes/app-navbar/main.js'
        },
        {
            name: 'app-menu',
            entryUri: '/mfes/app-menu/main.js'
        }
    ];
    let importMap = { imports: {} };
    mfeFrameworkApps.forEach((mfeFrameworkApp) => {
        importMap.imports[`${orgName}/${mfeFrameworkApp.name}`] = mfeFrameworkApp.entryUri;
    });
    if (config?.Apps) {
        config.Apps.forEach((app) => {
            const appImportKey = `${orgName}/${app.Name}`;
            const appImportValue = `${protocol}//${app.HostName || microFrontendHost}${app.EntryUri}`;
            importMap.imports[appImportKey] = appImportValue;
            app.AppImportMapEntry = {
                key: appImportKey,
                value: appImportValue
            };
            if (app.ConfigUri) {
                const configImportKey = `${orgName}/${app.Name}-config`;
                const configImportValue = `${protocol}//${app.HostName || microFrontendHost}${app.ConfigUri}`;
                importMap.imports[configImportKey] = configImportValue;
                app.ConfigImportMapEntry = {
                    key: configImportKey,
                    value: configImportValue
                };
            }
            // add MFE menu utility to import map
            if (app.MenuEntryUri) {
                const menuImportKey = `${orgName}/${app.Name}-menu`;
                const menuImportValue = `${protocol}//${app.HostName || microFrontendHost}${app.MenuEntryUri}`;
                importMap.imports[menuImportKey] = menuImportValue;
            }
        });
    }
    addDummyIfNotExist.forEach(mfe => {
        if (!importMap.imports[mfe]) {
            importMap.imports[mfe] = importMap.imports['@aclara/root-config'];
        }
    });
    return importMap;
}
/**
 * Generates and appends the import map script to the DOM.
 */
function generateImportMapAndAddToDom(config) {
    const importMap = constructImportMapWithApps(config);
    const scriptTag = document.createElement('script');
    scriptTag.type = 'systemjs-importmap';
    scriptTag.textContent = JSON.stringify(importMap);
    document.head.appendChild(scriptTag);
    window.System.prepareImport(true); // Inform SystemJS to re-evaluate the import map
}
/**
 * Loads the module configuration for each MFE application.
 */
async function loadAppModuleConfig(config) {
    const promises = config.Apps.map(app => {
        if (!app.ConfigImportMapEntry) {
            // AppModules with a menu utility module aren't initialized until getRemoteModules is successfully called
            if (!app.MenuEntryUri) {
                window.dispatchEvent(new CustomEvent('MfeAppModuleInitialized', { detail: { mfeAppName: app.Name } }));
            }
            return Promise.resolve();
        }
        return System.import(app.ConfigImportMapEntry.key)
            .then(async (module) => {
            config.loadMfeAppModules(app.Name, await module.getAppModules());
        })
            .catch(error => console.error(`Error loading config for ${app.Name}: ${error}`));
    });
    return Promise.all(promises);
}
/**
 * Registers and loads the MFE apps based on the configuration.
 */
function loadApps(config, services) {
    generateImportMapAndAddToDom(config);
    const data = {
        props: { config, services },
        loaders: {}
    };
    const routes = constructRoutes(microfrontendLayout, data);
    const applications = constructApplications({
        routes,
        loadApp: ({ name }) => System.import(name)
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });
    applications.forEach(registerApplication);
    layoutEngine.activate();
    start();
}
/**
 * Routes to the default application and state once all MFE modules are loaded.
 */
function routeToDefault(event) {
    const e = event;
    const mfeAppName = e?.detail?.mfeAppName;
    const defaultAppName = config?.DefaultState?.AppName || AO_DEFAULT_APP_NAME;
    const defaultRoute = config?.DefaultState.RouteIdentifier || AO_DEFAULT_ROUTE;
    if (mfeAppName == `${orgName}/${defaultAppName}`) {
        // Hide the loading overlay and show the main app container
        const loadingOverlay = document.getElementById("initialization-banner");
        const appContainer = document.getElementById("app-container");
        if (loadingOverlay && appContainer) {
            // Add the fade-out class to trigger the transition
            loadingOverlay.classList.add("fade-out");
            // Delay showing the app container until after the fade-out transition
            setTimeout(() => {
                loadingOverlay.style.display = "none"; // Hide it completely after animation
                appContainer.style.display = "block";
            }, 500); // Match the duration of the CSS transition
        }
        navigationUtilityService.mfeNavigator(defaultRoute, {});
        window.removeEventListener('MfeAppModuleLoaded', routeToDefault);
    }
}
// Initialize the app when this script is loaded
initializeApp();
