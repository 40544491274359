class NavigationUtilityService {
    // TODO these should match the mfe from the client's default route
    currentMfe = 'ao';
    currentState = 'default';
    navigators = [];
    navigator = (stateName, metadata) => {
        console.log('this is the default navigator function, should be changed by the current mfe. navigating to: ' + stateName);
    };
    // used to go from an Angular MFE to AngularJS. Currently just has one hardcoded string param. TODO: extend param functionality to an arbitrary data {}.
    goToAngularJSMfeStateWithParam = (mfeName, stateName, param) => {
        window.location.href = '#/' + mfeName + '/' + stateName + '/' + param;
    };
    goToMfe = (mfeName) => {
        window.location.href = '#/' + mfeName;
    };
    goToMfeState = (mfeName, stateName) => {
        console.log('navigating to ' + mfeName + ' / ' + stateName); // for state navigation debugging
        window.location.href = '#/' + mfeName + '/' + stateName;
    };
    // replaces the is() functionality. Just check against the current MFE and state names.
    // may need to add custom behavior for legacy MFEs that can't ensure state names matching their URLs.
    isCurrentState = (route) => {
        let segments = route.split('.');
        let mfeName = segments[0];
        let stateName = segments.slice(1).join('/');
        return this.currentMfe == mfeName && this.currentState == stateName;
    };
    mfeNavigator = (route, metadata) => {
        const parentRoute = route.split('.')[0];
        const navigator = this.navigators.find(x => x.routeSegment === parentRoute);
        if (!navigator) {
            const routeUrl = `#/${route.replace(/\./g, '/')}`;
            window.location.href = routeUrl;
            return;
        }
        navigator.navigatorFn(route, metadata);
    };
    registerNavigator = (navigator) => {
        const index = this.navigators.findIndex(x => x.routeSegment === navigator.routeSegment);
        if (index !== -1) {
            // Replace the existing navigator
            this.navigators[index] = navigator;
        }
        else {
            // Add the new navigator
            this.navigators.push(navigator);
        }
    };
    unregisterNavigator = (routeSegment) => {
        const index = this.navigators.findIndex(x => x.routeSegment === routeSegment);
        if (index !== -1) {
            this.navigators.splice(index, 1);
        }
    };
    setNavigator = (fn) => {
        this.navigator = fn;
    };
}
// Export both the type and the singleton instance of the service.
const navigationUtilityService = new NavigationUtilityService();
export { NavigationUtilityService, navigationUtilityService };
