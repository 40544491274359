/**
 * Main entry point for initializing the micro frontend application.
 * This file handles high-level orchestration, including:
 * - Core service initialization.
 * - Import map generation and management.
 * - Loading and registering micro frontends (MFEs).
 * - Attaching event listeners for application lifecycle events.
 */
import { navigateToUrl, registerApplication, start, } from 'single-spa';
import { AO_DEFAULT_APP_NAME, AO_DEFAULT_ROUTE, AO_ORG_NAME } from './constants';
import { createMfeAppDomStructure, initializeCoreServices, generateImportMapAndAddToDom, getSharedServices, } from './utils';
import '../assets/styles/_ao-base.scss';
let config;
// Tracks the initialization status of each registered MFE app
const applicationTracker = {};
/**
 * Initializes the micro frontend application.
 * This function:
 * - Initializes shared services.
 * - Loads client-specific settings and configurations.
 * - Sets up import maps for MFEs.
 * - Registers and starts MFE applications.
 * - Initializes shadow DOMs for all MFE containers.
 * - Navigates to the default route.
 */
async function initializeApp() {
    const services = getSharedServices();
    // Initialize core services such as authentication and client settings
    await initializeCoreServices(services);
    // Load client settings and configurations
    config = await services.clientSettingsService.clientModuleConfiguration;
    // Initialize application tracker for all apps
    initializeApplicationTracker(config);
    // Generate and add the import map to the DOM
    generateImportMapAndAddToDom(config);
    // Attach lifecycle event listeners for application state management
    attachLifecycleEventListeners();
    // Register and start MFE applications
    loadApps(config, services);
    // Load additional module configurations (e.g., legacy resources)
    await loadAppModuleConfig(config);
    // Manually bootstrap the default MFE app prior to starting Single-SPA
    await manuallyBootstrapDefaultApp(config, services);
    // Start Single-SPA
    start();
    // Hide the initialization banner to show the application.
    hideInitializationBanner();
    // Navigate to the default route
    routeToDefault(config?.DefaultState?.RouteIdentifier);
}
/**
 * Attaches event listeners for handling application lifecycle events:
 * - `MfeAppModuleInitialized`: Tracks initialization of individual MFEs.
 * - `MfeAppModuleLoaded`: Routes to the default state once all modules are loaded.
 */
function attachLifecycleEventListeners() {
    window.addEventListener('MfeAppModuleInitialized', handleMfeAppInitialized);
}
/**
 * Handles the initialization event for individual MFE applications.
 * Tracks the status of each app and triggers a global event once all are initialized.
 *
 * @param {Event} event - The event dispatched when an MFE app is initialized.
 */
function handleMfeAppInitialized(event) {
    const e = event;
    const mfeAppName = e?.detail?.mfeAppName;
    if (mfeAppName && applicationTracker[mfeAppName] !== undefined) {
        applicationTracker[mfeAppName] = true; // Mark the app as initialized
        // Check if all apps have been initialized
        const allInitialized = Object.values(applicationTracker).every(status => status === true);
        if (allInitialized) {
            config.AppModulesAreInitialized = true;
            // The following 'AllMfeAppModulesHaveInitialized' event triggers the menu loading service
            // to construct the menu from menu item entries of the initialized MFE apps.
            window.dispatchEvent(new CustomEvent('AllMfeAppModulesHaveInitialized'));
            window.removeEventListener('MfeAppModuleInitialized', handleMfeAppInitialized);
        }
    }
}
/**
 * Visually removes the initialization banner to show the microfrontend application.
 */
function hideInitializationBanner() {
    // Hide the initialization banner and show the main app container
    const initializationBanner = document.getElementById("initialization-banner");
    const appContainer = document.getElementById("app-container");
    if (initializationBanner && appContainer) {
        // Add the fade-out class to trigger the transition
        initializationBanner.classList.add("fade-out");
        // Delay showing the app container until after the fade-out transition
        setTimeout(() => {
            initializationBanner.style.display = "none"; // Hide it completely after animation
            appContainer.style.display = "block";
        }, 500); // Match the duration of the CSS transition
    }
}
/**
 * Initializes the application tracker for all MFEs in the configuration.
 * The tracker is used to monitor the initialization status of each app.
 *
 * @param {ClientMfeConfig} config - The client-specific configuration containing MFE details.
 */
function initializeApplicationTracker(config) {
    config.Apps.forEach(app => {
        applicationTracker[app.Name] = false; // Mark all apps as not initialized initially
    });
}
/**
 * Loads the module configuration for each MFE application.
 *
 * @param {ClientMfeConfig} config - The client-specific configuration.
 * @returns {Promise<void>} - A promise resolving once all module configurations are loaded.
 */
async function loadAppModuleConfig(config) {
    const promises = config.Apps.map(app => {
        if (!app.ConfigImportMapEntry) {
            window.dispatchEvent(new CustomEvent('MfeAppModuleInitialized', { detail: { mfeAppName: app.Name } }));
            return Promise.resolve();
        }
        return System.import(app.ConfigImportMapEntry.key)
            .then(async (module) => {
            config.loadMfeAppModules(app.Name, await module.getAppModules(app));
            window.dispatchEvent(new CustomEvent('MfeAppModuleInitialized', { detail: { mfeAppName: app.Name } }));
        })
            .catch(error => console.error(`Error loading config for ${app.Name}: ${error}`));
    });
    await Promise.all(promises);
}
/**
 * Registers and loads MFE applications.
 * This function:
 * - Constructs routes and applications based on the provided layout.
 * - Wraps `@myorg/app-menu` and `@myorg/app-navbar` in shadow DOM.
 * - Registers applications with `single-spa`.
 * - Activates the layout engine and starts the `single-spa` lifecycle.
 *
 * @param {ClientMfeConfig} config - The client-specific configuration containing MFE details.
 * @param {any} services - The shared services used by MFEs.
 */
function loadApps(config, services) {
    config?.Apps?.forEach(app => {
        createMfeAppDomStructure(app.Name, config);
        registerApplication({
            name: app.Name,
            app: () => {
                return System.import(`${AO_ORG_NAME}/${app.Name}`)
                    .then((module) => {
                    if (module.default && typeof module.default === "object") {
                        return module.default;
                    }
                    else {
                        console.error(`Module ${app.Name} does not export an application correctly`);
                    }
                })
                    .catch(error => {
                    console.log(`Error loading app ${app.Name}:`, error);
                    // throw error;
                    // let mostRecentMap = (window as any).importMapOverrides.getOverrideMap();
                    // if (name in mostRecentMap.imports && !(name in initialOverrideMap.imports)) {
                    //   // then this value was just added to the overrides and an auto-refresh is required
                    //   // (i.e., a new visitor to the page)
                    //   // subsequent visits should be fine due to the import map overrides being in localStorage
                    //   location.reload();
                    // }
                });
            },
            activeWhen: app.Route ? [`#/${app.Route}`] : () => true,
            customProps: { config, services }
        });
    });
}
/**
 * Manually loads and bootstraps an MFE app before starting Single-Spa.
 */
async function manuallyBootstrapDefaultApp(config, services) {
    const defaultAppName = `${AO_ORG_NAME}/${config?.DefaultState?.AppName || AO_DEFAULT_APP_NAME}`;
    const props = {
        name: config?.DefaultState?.AppName,
        config: config,
        services: services
    };
    try {
        console.log(`[MFE Bootstrap] Bootstrapping ${defaultAppName} before single-spa starts`);
        const appModule = await System.import(defaultAppName);
        if (appModule.bootstrap) {
            await appModule.bootstrap(props);
            console.log(`[MFE Bootstrap] ${defaultAppName} manually bootstrapped`);
        }
        else {
            console.error(`[MFE Bootstrap] ${defaultAppName} does not expose a valid bootstrap function`);
        }
    }
    catch (error) {
        console.error(`[MFE Bootstrap] Failed to bootstrap ${defaultAppName}:`, error);
    }
}
/**
 * Routes to the default application and state once the default MFE app is loaded.
 */
function routeToDefault(defaultRouteIdentifier) {
    if (!defaultRouteIdentifier) {
        defaultRouteIdentifier = AO_DEFAULT_ROUTE;
    }
    navigateToUrl(`#/${defaultRouteIdentifier.replace(/\./g, '/')}`);
}
// Initialize the micro frontend application on script load
initializeApp();
