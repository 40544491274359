class NavigationUtilityService {
    // TODO these should match the mfe from the client's default route
    currentMfe = 'ao';
    currentState = 'default';
    navigator = (stateName, metadata) => {
        console.log('this is the default navigator function, should be changed by the current mfe. navigating to: ' + stateName);
    };
    // used to go from an Angular MFE to AngularJS. Currently just has one hardcoded string param. TODO: extend param functionality to an arbitrary data {}.
    goToAngularJSMfeStateWithParam = (mfeName, stateName, param) => {
        window.location.href = '#/' + mfeName + '/' + stateName + '/' + param;
    };
    goToMfe = (mfeName) => {
        window.location.href = '#/' + mfeName;
    };
    goToMfeState = (mfeName, stateName) => {
        console.log('navigating to ' + mfeName + ' / ' + stateName); // for state navigation debugging
        window.location.href = '#/' + mfeName + '/' + stateName;
    };
    // replaces the is() functionality. Just check against the current MFE and state names.
    // may need to add custom behavior for legacy MFEs that can't ensure state names matching their URLs.
    isCurrentState = (route) => {
        let segments = route.split('.');
        let mfeName = segments[0];
        let stateName = segments.slice(1).join('/');
        return this.currentMfe == mfeName && this.currentState == stateName;
    };
    mfeNavigator = (route, metadata) => {
        let segments = route.split('.');
        let mfeName = segments[0]; // should be 'ao' or 'phase-detect' or other mfe name
        let stateName = segments.slice(1).join('/');
        if (mfeName == this.currentMfe) {
            // these console logs are just for debugging, can be removed if annoying
            console.log('intra-MFE navigation (' + mfeName + ') - go to state ' + stateName);
            this.navigator(route, metadata ?? null);
            this.currentState = stateName;
        }
        else {
            console.log('inter-MFE navigation (' + this.currentMfe + ' => ' + mfeName + ') - go to state ' + stateName);
            this.currentMfe = mfeName;
            this.currentState = stateName;
            window.location.href = '#/' + mfeName + '/' + stateName;
        }
    };
    setNavigator = (fn) => {
        this.navigator = fn;
    };
}
// Export both the type and the singleton instance of the service.
const navigationUtilityService = new NavigationUtilityService();
export { NavigationUtilityService, navigationUtilityService };
